import firebase from "firebase";
import { createUuid } from '../utils/uuid';

const ROOM_COLLECTION_NAME = "rooms";
const initDB = () => firebase.firestore();

export const getRooms = () => {
  const db = initDB();
  db.collection(ROOM_COLLECTION_NAME).get()
  .then((room) => {
    return room
  })
  .catch((err) => {
    console.error(err);
  })
};

export const getRoom = async (roomId) => {
  const db = initDB();
  const resp = await db.collection(ROOM_COLLECTION_NAME)
  .doc(roomId)
  .get()
  .then((doc) => {
    return doc.data();
  })
  .catch((err) => {
    console.error(err);
  })
  return resp;
};

export const createRoom = async (name) => {
  const db = initDB();
  const roomsDocId = db.collection(ROOM_COLLECTION_NAME).doc().id;
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomsDocId).set({
    // docId: docId,
    name: name,
    back_number: [],
    users: {},
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
  .then(() => {
    return roomsDocId;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return "";
  });
  return resp;
}

export const addUser = async (roomId, name) => {
  const db = initDB();
  const userId = createUuid()
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomId).set({
    users: {
      [userId]: {
        name: name,
        is_bingo: false,
        is_reach: false,
        joined_at: firebase.firestore.FieldValue.serverTimestamp()
      }
    }
  }, {merge: true})
  .then(() => {
    return userId;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return "";
  });
  return resp;
}

export const setBingo = (roomId, userId) => {
  const db = initDB();
  db.collection(ROOM_COLLECTION_NAME).doc(roomId)
  .update({
    [`users.${userId}.is_bingo`]: true,
    [`users.${userId}.is_reach`]: false,
  }, {merge: true})
  .then(() => {

    return true;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return false;
  });
  return false;
}

export const setReach = (roomId, userId) => {
  const db = initDB();
  db.collection(ROOM_COLLECTION_NAME).doc(roomId)
  .update({
    [`users.${userId}.is_reach`]: true,
  }, {merge: true})
  .then(() => {

    return true;
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
    return false;
  });
  return false;
}

export const addBackNumber = async (roomId, number) => {
  const db = initDB();
  const resp = db.collection(ROOM_COLLECTION_NAME).doc(roomId).update({
    back_number: firebase.firestore.FieldValue.arrayUnion(number)
  })
  .then(() => {
      return true;
  })
  .catch((error) => {
      console.error("Error writing document: ", error);
      return false;
  });
  return resp;
}

export const listenRoom = async (roomId, callback) => {
  const db = initDB();
  const resp = await db.collection(ROOM_COLLECTION_NAME).doc(roomId)
  .onSnapshot(function(doc) {
    callback(doc.data());
  });
  return resp;
}
