import React from 'react';
import styled from 'styled-components';

export const Header = (props) => {
  return (
    <React.Fragment>
      <Wrapper>
        <HeaderTitle href="/">無料オンラインビンゴ</HeaderTitle>
      </Wrapper>
    </React.Fragment>
  )
}

const Wrapper = styled.div`
  background: #f4b44b;
  display: flex;
  align-items: center;
  padding: 20px;
  position: fixed;
  width: 100vw;
  box-shadow: 0px 2px 5px #797979;
  box-sizing: border-box;
`;
const HeaderTitle = styled.a`
  text-decoration: none;
  font-family: 'Kosugi Maru', sans-serif;
  letter-spacing: 4px;
  font-size: 24px;
  color: white;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;