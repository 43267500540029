import { MIN_NUMBER, MAX_NUMBER } from '../constants/index';

/* 配列をシャッフル */
export const shuffle = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/* [1,2, ・・・, 75] を生成 */
export const generateList = (min=MIN_NUMBER, max=MAX_NUMBER) => {
  let lst = [];
  for(let i = min; i <= max; i++){
    lst.push(i);
  }
  return lst
}

/* Wrapper function: 生成した配列をシャッフル */
export const generateShuffleList = (min=MIN_NUMBER, max=MAX_NUMBER) => {
  const lst = generateList(min, max);
  return shuffle(lst);
}

// 案1
// [[1, 5, 13, 14, 15],
// [16,],
// [],
// [],
// []]


// 案2
// [
//   [1, 16, 31, 46, 61],
//   [1, 16, 31, 46, 61],
//   [1, 16, 31, 46, 61],
//   [1, 16, 31, 46, 61],
//   [1, 16, 31, 46, 61],
// ]

/* 最初の5個をとる */
export const random5list = (min, max) => {
  const generated = generateShuffleList(min, max)
          .slice(0, 5)
          .map(n => {
            return {
              number: n,
              punchOuted: false,
              isHit: false,
              isBingo: false,
              isReach: false,
            }
          })
  return generated
}

/* 最初の5個をとる */
/* 1-15	16-30	31-45	46-60	61-75 */
export const bingoCard = () => {
  let generated = [];
  for(let i = 0; i <= 4; i++){
    const start = i * 15 + 1;
    const end = (i + 1) * 15;
    generated.push(random5list(start, end));
  };
  return generated;
}

/* 転置行列の作成 */
export const transpose = lst => lst[0].map((_, c) => lst.map(r => r[c]));
