import React from 'react';
import styled from 'styled-components';
import Background from '../../img/yonezu.svg';


export const Modal = (props) => {
  const { text, isDisplay, closeModal} = props;
  const onClick =(e) => {
    if (e.target.id !== 'modal-content') {
      closeModal();
    }
  }

  return (
    <React.Fragment>
      <Wrapper  onClick={(e) => onClick(e)} id={'modal-wrapper'} isDisplay={isDisplay}>
        <Content id={'modal-content'}>
          <Img></Img>
          {text}
        <Button id={'modal-button'}>OK</Button>
        </Content>
        
      </Wrapper>
    </React.Fragment>
  )
}




const Wrapper = styled.div`
  display: ${props => props.isDisplay ? 'flex' : 'none'};
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.6);
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const Content = styled.div`
  width: 80vw;
  height: 150px;
  max-height: 30vh;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  box-shadow: 0px 2px 5px #797979;
  border-radius: 12px;
  border-bottom: 5px solid #dcdada;
`;
const Img = styled.div`
  height: 20px;
  width: 100%;
  border-radius: 3px 3px 0 0;
  /* background-image: url(${Background}); */
  /* background-color: crimson; */

`;
const Button = styled.div`
  width: 80%;
  height: 50px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background: crimson;
  color: white;
  border: 1px solid crimson;
  border-radius: 3px;
  margin-bottom: 18px;
  font-weight: bold;
  letter-spacing: 6px;
  text-indent: 6px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;