import React from 'react';
import styled, { css, keyframes } from 'styled-components'
import { Modal } from './modal';
import { HelmetWrapper } from './helmet'
import { bingoCard, transpose } from '../../utils/list';
import { listenRoom, setBingo, setReach, getRoom } from '../../clients/firebase';
import Background from '../../img/yonezu.svg';

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bingoCard: this.storageCheck(),
      room: null,
      isBingo: false,
      isReach: false,
      closedBingoModal: this.storageCheckClosedModal('closedBingoModal'),
      closedReachModal: this.storageCheckClosedModal('closedReachModal'),
    };
  }

  storageCheck = () => {
    const sessBingoCard = JSON.parse(sessionStorage.getItem('bingoCard'));

    if (sessBingoCard) {
      return sessBingoCard;
    } else {
      const initBingoCard = bingoCard()
      sessionStorage.setItem('bingoCard', JSON.stringify(initBingoCard));
      return initBingoCard;
    }
  }

  storageCheckClosedModal = (key) => {
    return  sessionStorage.getItem(key) === 'true';
  }

  UNSAFE_componentWillMount = async () => {
    this.initBingoCardCenter();
    const roomId = this.props.match.params.room_id;
    const roomData = await getRoom(roomId);
    this.loadBackNumber(roomData.back_number);
    await listenRoom(roomId, (resp) => this.setRoom(resp));
  }

  loadBackNumber = (backNumber) => {
    const { bingoCard } = this.state;
    const newBingoCard = backNumber.reduce((bingoAcc, number) => {
      const bingoC = this.isHit(bingoAcc, number);
      return bingoC;
    }, bingoCard);
    this.setState({ bingoCard: newBingoCard });
  }

  initBingoCardCenter = () => {
    const { bingoCard } = this.state;
    const center = bingoCard[2][2];
    center.isHit = true;
    center.number = -1;

    bingoCard[2][2] = center;
    this.saveBingoCard(bingoCard)
  }

  setRoom = (room) => {
    const { isBingo, bingoCard } = this.state;

    if (isBingo) return;
    const backNumber = room.back_number;
    const newBingoCard = this.isHit(bingoCard, backNumber[backNumber.length - 1]);
    this.setState({ bingoCard: newBingoCard });

    this.setState({ room: room });
  }

  isHit = (bingoCard, number) => {
    let idx = -1;
    if (number < 16) {
      idx = 0;
    } else if (number < 31) {
      idx = 1;
    } else if (number < 46) {
      idx = 2;
    } else if (number < 61) {
      idx = 3;
    } else {
      idx = 4;
    }

    if (idx === -1) return;
    const horizon = bingoCard[idx];
    horizon.forEach(obj => {
      if (obj.number === number) obj.isHit = true;
    })

    bingoCard[idx] = horizon;
    return bingoCard;
  }

  bingoJudge = () => {
    const { bingoCard } = this.state;
    let horizontalBingo = false;
    let verticalBingo = false;
    let diagonalBingo = false;

    let horizontalReach = false;
    let verticalReach = false;
    let diagonalReach = false;

    bingoCard.forEach((horizon, y) => {
      const totalPunched = horizon.filter(obj => obj.punchOuted === true).length;
      if (totalPunched >= 4) {
        horizontalReach = true;
        horizon.forEach((obj, x) => {
          if (obj.punchOuted) bingoCard[y][x].isReach = true;
        })
      }

      if (totalPunched >= 5) {
        horizontalBingo = true;
        horizon.forEach((obj, x) => {
          if (obj.punchOuted) bingoCard[y][x].isBingo = true;
        })
      }
    })

    let bingoCardT = transpose(bingoCard)
    bingoCardT.forEach((vertical, x) => {
      const totalPunched = vertical.filter(obj => obj.punchOuted === true).length;
      if (totalPunched >= 4) {
        verticalReach = true
        vertical.forEach((obj, y) => {
          if (obj.punchOuted) bingoCard[y][x].isReach = true;
        })
      }

      if (totalPunched >= 5) {
        verticalBingo = true
        vertical.forEach((obj, y) => {
          if (obj.punchOuted) bingoCard[y][x].isBingo = true;
        })
      }
    })

    let totalPunched1 = 0;
    let totalPunched2 = 0;
    for(let i = 0; i < 5; i++){
      if (bingoCard[i][i].punchOuted === true) totalPunched1++; //　＼
      if (bingoCard[4 - i][i].punchOuted === true) totalPunched2++;// /
    }

    if (totalPunched1 >= 4) {
      for(let i = 0; i < 5; i++){
        if (bingoCard[i][i].punchOuted === true) bingoCard[i][i].isReach = true;
      }
    }

    if (totalPunched1 >= 5) {
      for(let i = 0; i < 5; i++){
        if (bingoCard[i][i].punchOuted === true) bingoCard[i][i].isBingo = true;
      }
    }

    if (totalPunched2 >= 4) {
      for(let i = 0; i < 5; i++){
        if (bingoCard[4 - i][i].punchOuted === true) bingoCard[4 - i][i].isReach = true;
      }
    }

    if (totalPunched2 >= 5) {
      for(let i = 0; i < 5; i++){
        if (bingoCard[4 - i][i].punchOuted === true) bingoCard[4 - i][i].isBingo = true;
      }
    }


    if (totalPunched1 >= 5 || totalPunched2 >= 5) diagonalBingo = true
    if (totalPunched1 >= 4 || totalPunched2 >= 4)  diagonalReach = true

    this.saveBingoCard(bingoCard);

    return {
      isBingo: horizontalBingo || verticalBingo || diagonalBingo,
      isReach: horizontalReach || verticalReach || diagonalReach
    };
  }

  isReach = () => {}

  saveBingoCard = (bingoCard) => {
    this.setState({ bingoCard: bingoCard });
    sessionStorage.setItem('bingoCard', JSON.stringify(bingoCard))
  }

  punchOut = (x, y) => {
    const { bingoCard } = this.state;
    const card = bingoCard[x][y];
    if (card.isHit && !card.punchOuted) {
      card.punchOuted = true;
      bingoCard[x][y] = card;

      this.saveBingoCard(bingoCard);
    }

    const judge = this.bingoJudge();
    const roomId = this.props.match.params.room_id;
    const userId = this.props.match.params.user_id;
    if (judge.isBingo) {
      setBingo(roomId, userId)
      this.setState({ isBingo: true });
    }

    if (judge.isReach) {
      setReach(roomId, userId);
      this.setState({ isReach: true });
    }
  }

  closeModal = (kind) => {
    if (kind === "bingo") {
      this.setState({ closedBingoModal: true });
      sessionStorage.setItem('closedBingoModal', true);
    } else {
      this.setState({ closedReachModal: true });
      sessionStorage.setItem('closedReachModal', true);
    }
  }
  getUser = () => {
    const { room } = this.state;
    const userId = this.props.match.params.user_id;
    if (!userId) return

    const users = room.users;
    const userInfo = users[userId] || ""
    return userInfo;
  }


  render() {
    const { bingoCard, room, isBingo, isReach, closedBingoModal, closedReachModal} = this.state;

    return (
      <Wrapper>
        { room && (
            <React.Fragment>
              <HelmetWrapper title={`${this.getUser().name}さんのビンゴカード | 無料オンラインビンゴ`} description={`${this.getUser().name}さんのビンゴカード | ${room.name || "本日"}のビンゴ部屋`} />
              <Modal closeModal={() => this.closeModal("bingo")} isDisplay={isBingo && !closedBingoModal} text={"ビンゴ"}></Modal>
              <Modal closeModal={() => this.closeModal("reach")} isDisplay={isReach && !closedReachModal} text={"リーチ"}></Modal>
              <Info>
                <InfoLabel>概要</InfoLabel>
                <InfoRow>
                  <Label>部屋</Label>
                  <Value>{room.name}</Value>
                  </InfoRow>
                <InfoRow>
                  <Label>名前</Label>
                  <Value>{this.getUser().name}</Value>
                </InfoRow>
              </Info>
            <BingoCardWrapper>
              <BingoCard>
                <BingoTitle>
                  <BingoTitleLetter>B</BingoTitleLetter>
                  <BingoTitleLetter>I</BingoTitleLetter>
                  <BingoTitleLetter>N</BingoTitleLetter>
                  <BingoTitleLetter>G</BingoTitleLetter>
                  <BingoTitleLetter>O</BingoTitleLetter>
                </BingoTitle>
                {transpose(bingoCard).map((lst, x) =>
                  <BingoRow>
                    {lst.map((v, y) =>
                      <BingoCell onClick={() => this.punchOut(y, x)}
                        isHit={bingoCard[y][x].isHit}
                        punchOuted={bingoCard[y][x].punchOuted}
                        isReach={bingoCard[y][x].isReach}
                        isBingo={bingoCard[y][x].isBingo}>
                        {v.number === -1 ? "" : v.number}
                      </BingoCell>
                    )}
                  </BingoRow>
                )}
              </BingoCard>
            </BingoCardWrapper>
            <AdWrapper>
              <a href="https://px.a8.net/svt/ejp?a8mat=3HEBHN+13W2B6+CO4+15YMPT" rel="nofollow">
                <img border="0" width="320" height="73" alt="" src="https://www29.a8.net/svt/bgt?aid=210620075067&wid=001&eno=01&mid=s00000001642007048000&mc=1" />
              </a>
              <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3HEBHN+13W2B6+CO4+15YMPT" alt="" />
            </AdWrapper>
          </React.Fragment>
        )}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  height: 100vh;
`;

const Info = styled.div`
  background: white;
  width: 95%;
  box-shadow: 0px 2px 5px #797979;
  border-radius: 3px;
  padding: 10px 25px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin: 85px 0 20px 0;
  border-radius: 12px;
  /* background: coral; */
  border-bottom: 5px solid #dcdada;
`;

const InfoLabel = styled.div`
  color: #797979;
  font-size: 14px;
  margin: 4px 0 4px 0;
`;

const InfoRow = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #565656;
  width: 80px;
`;

const Value = styled.div`
  color: #797979;
  text-align: right;
`;



const BingoCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BingoCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: cadetblue;
  padding: 10px 20px 20px 20px;
  border-radius: 3px;
  box-shadow: 0px 2px 5px #797979;
  /* background-image: url(${Background}); */
  border-radius: 12px;
  border-bottom: 5px solid #487879;;
`;

const BingoTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BingoTitleLetter = styled.div`
  color: white;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  font-family: 'arial black';
  text-shadow: 0px 0px 2px grey;
`;


const BingoRow = styled.div`
  width: 250px;
  height: 50px;
  display: flex;
`;

const fadeIn = keyframes`
  from {
    opacity: 0.9;
  }
  to {
    opacity: 1;
  }
`;

const BingoCell = styled.button`
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  border-style: none;
  border: 2px solid cadetblue;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: ${props => props.isHit ? 'blue' : 'white'} */
  color: #565656;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background-color: ${props => {
    if (props.punchOuted){
      return 'antiquewhite';
    } else if (props.isHit){
      return '#fff';
    } else {
      return 'white'
    }
  }};
  animation: ${props => props.isHit && !props.punchOuted ? css`${fadeIn} 1s linear infinite;` : '' };
`;

const AdWrapper = styled.div`
  margin-top: 20px;
  /* position: fixed;
  bottom: 0; */
`;

export default User;
