import React from 'react';
import styled from 'styled-components'
import QRCode from "qrcode.react";
import { BackNumber } from './backnumber';
import { HelmetWrapper } from './helmet';
import { generateShuffleList } from '../../utils/list';
import { addBackNumber, getRoom, listenRoom } from '../../clients/firebase';
import { MIN_NUMBER, MAX_NUMBER, SP_SIZE } from '../../constants/index';
import { hostName } from '../../utils/host'
import Next from '../../img/next.svg';

class Owner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.storageCheck(),
      backNumber: [],
      current: null,
      room: null,
      tabMode: 'all',
      isGaragara: false,
    };
  }

  UNSAFE_componentWillMount = async () => {
    const roomId = this.props.match.params.room_id;
    const roomData = await getRoom(roomId);
    this.setState({ room: roomData, backNumber: roomData.back_number });
    await listenRoom(roomId, (resp) => this.setRoom(resp));
  }

  storageCheck = () => {
    const sessList = JSON.parse(sessionStorage.getItem('list'));
    if (sessList && sessList.list) {
      return sessList.list;
    } else {
      const initList= generateShuffleList()
      sessionStorage.setItem('list', JSON.stringify({list: initList}))
      return initList;
    }
  }

  setRoom = (room) => {
    this.setState({ room: room });
  }

  sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, 3000));
  }

  setDeceleratingTimeout = (callback, factor, times) => {
    const internalCallback = (tick, counter) => {
      return () => {
        console.log("tick: ", tick);
        if (--tick >= 0) {
          setTimeout(internalCallback, ++counter * factor);
          callback();
        }
      }
    }
    // }(times, 0)
    setTimeout(() => internalCallback(times, 0), factor);
  };

  slowDownInterval = async (ms, limit=10) => {
    await this._slowDownInterval(ms, limit, 0);
  }

  _slowDownInterval = async (ms, limit, counter) => {
    const sleepTimme = ms + (counter * 10);
    await new Promise(resolve => {
      setTimeout(resolve, sleepTimme);
    });

    if (limit > counter) {
      const randNum = Math.floor(Math.random() * (MAX_NUMBER - MIN_NUMBER) + MIN_NUMBER);
      this.setState({ current: randNum });
      await this._slowDownInterval(ms, limit, counter + 1);
    }
  }

  garagara = async (e) => {
    const { list, backNumber } = this.state;
    if (list.length > 0) {
      this.setState({ isGaragara: true });
      const head = list[0]
      const tail = list.slice(1, list.length);
      await this.slowDownInterval(50, 20);

      const result = await addBackNumber(this.props.match.params.room_id, head);
      if (!result) return;
      this.setState({
        list: tail,
        backNumber: backNumber.concat([head]),
        current: head,
        isGaragara: false,
      });
      sessionStorage.setItem('list', JSON.stringify({list: tail}))
    }
  }

  getUserKeyArray = (mode) => {
    const { room } = this.state;
    const userKeyArray = Object.keys(room.users);
    if (mode === "bingo") {
      return userKeyArray.filter(user => room.users[user].is_bingo);
    } else if (mode === "reach") {
      return userKeyArray.filter(user => room.users[user].is_reach);
    } else {
      return userKeyArray;
    };
  }

  setTabMode = (mode) => {
    this.setState({ tabMode: mode });
  }


  render() {
    const { backNumber, current, room, tabMode, isGaragara } = this.state;
    return (
      <React.Fragment>
        {room ? (
          <Wrapper>
            <ContentWrapper>
            <HelmetWrapper title={`${(room.name || "本日")}のビンゴ部屋 | 無料オンラインビンゴ`} description={`${(room.name || "本日")}のビンゴ部屋です。Let's Bingo!!`} />
            <SPContent>PC・タブレットのみで<br></br>対応しているページです</SPContent>
            <UserListWrapper>
              <TabWrapper>
                <UserTab isOn={tabMode === "all"} onClick={(e) => this.setTabMode("all")}>参加者</UserTab>
                <UserTab isOn={tabMode === "reach"} onClick={(e) => this.setTabMode("reach")}>リーチ</UserTab>
                <UserTab isOn={tabMode === "bingo"} onClick={(e) => this.setTabMode("bingo")}>ビンゴ</UserTab>
              </TabWrapper>
              <UserList>
                {this.getUserKeyArray(tabMode).map(key => <User>{room.users[key].name}</User>)}
              </UserList>
            </UserListWrapper>
            <Main>
              <GaraGara>{current || "Let's Bingo!"}</GaraGara>
              <GaraGaraButton onClick={(e) => !isGaragara ? this.garagara(e) : 'none' }><NextIcon src={Next}  alt="fireSpot"></NextIcon>NEXT</GaraGaraButton>
              <BackNumberWrapper>
                <BackNumber numberList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]} backNumber={backNumber} initialStr={'B'} />
                <BackNumber numberList={[16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]} backNumber={backNumber} initialStr={'I'} />
                <BackNumber numberList={[31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45]} backNumber={backNumber} initialStr={'N'} />
                <BackNumber numberList={[46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60]} backNumber={backNumber} initialStr={'G'} />
                <BackNumber numberList={[61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75]} backNumber={backNumber} initialStr={'O'} />
              </BackNumberWrapper>
            </Main>
            <Join>
              <AdWrapper>
              <a href="https://px.a8.net/svt/ejp?a8mat=3HEBHM+8BH22A+50+2I464H" rel="nofollow">
                <img border="0" width="150" height="125" alt="" src="https://www28.a8.net/svt/bgt?aid=210620074503&wid=001&eno=01&mid=s00000000018015136000&mc=1" />
              </a>
              <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3HEBHM+8BH22A+50+2I464H" alt="" />
              <a href="https://px.a8.net/svt/ejp?a8mat=3HEBHM+8AVMGI+3JTE+601S1" rel="nofollow">
                <img border="0" width="150" height="125" alt="" src="https://www28.a8.net/svt/bgt?aid=210620074502&wid=001&eno=01&mid=s00000016565001008000&mc=1" />
              </a>
              <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3HEBHM+8AVMGI+3JTE+601S1" alt="" />
              </AdWrapper>
              <QRCodeWrapper>
                <QRCodeDesc>参加はこちらから</QRCodeDesc>
                <QRCode value={`${hostName()}/${this.props.match.params.room_id}/join`} size={110} />
              </QRCodeWrapper>
            </Join>
            </ContentWrapper>
          </Wrapper>
        ) : (
          <div>no</div>
        )}
      </React.Fragment>

    )
  }
}

const Wrapper = styled.div`
  padding-top: 64px;
  height: calc(100vh - 64px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  /* align-items: center; */
  justify-content: center;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  padding-bottom: 50px;
  padding-top: 100px;
`;
const SPContent = styled.div`
  display: none;
  flex-flow: column;
  align-items: center;
  color: #333333;
  line-height: 24px;
  text-align: center;

  @media screen and (max-width: ${SP_SIZE}) {
    display: flex;
  }
`;
const Main = styled.div`
  display: flex;
  flex-flow: column;
  /* justify-content: center; */
  align-items: center;
  @media screen and (max-width: ${SP_SIZE}) {
    display: none;
  }
`;
const GaraGara = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 100%;
  background: white;
  border-radius: 6px;
  color: gray;
  margin: 0 0 15px 0;
  font-size: 75px;
  box-shadow: 0px 2px 5px #797979;
  border-radius: 12px;
  border-bottom: 5px solid #dcdada;
`;
const GaraGaraButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff1e48;
  border: none;
  color: white;
  padding: 7px 30px;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 2px 5px #797979;
  transition: .25s ease;
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-radius: 12px;
  border-bottom: 5px solid crimson;
  margin-bottom: 6px;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 5px;
  text-indent: 5px;
  height: 45px;
  box-sizing: border-box;

  &:active {
    transform: translateY(5px);
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const NextIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
const BackNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  background: cadetblue;
  align-items: center;
  padding: 0px 8px 10px 8px;
  margin: 10px 0 0 0;
  box-shadow: 0px 2px 5px #797979;
  border-radius: 12px;
  border-bottom: 5px solid #487879;
`;
const UserQRWrapper = styled.div`
  margin: 20px 0px 0;
  display: flex;
  justify-content: space-between;
  width: 685px;
  align-items: flex-end;
  @media screen and (max-width: ${SP_SIZE}) {
    display: none;
  }
`;
const UserListWrapper = styled.div`
  /* margin-top: 40px; */
`;
const UserList = styled.div`
  background-color: white;
  color: gray;
  height: 404px;
  width: 270px;
  padding: 10px 15px 20px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px #797979;
  border-radius: 0 12px 12px 12px;
  border-bottom: 5px solid #dcdada;
  margin-right: 20px;
`;

const UserTab = styled.div`
  padding: 10px 18px;
  border-radius: 6px 6px 0 0;
  margin-right: 2px;
  font-size: 15px;
  font-family: 'Kosugi Maru', sans-serif;
  color: ${props => props.isOn ? 'white' : 'gray'};
  background-color: ${props => props.isOn ? 'gray' : 'white'};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const TabWrapper = styled.div`
  display: flex;
`;
const User = styled.div`
  margin-bottom: 4px;
  font-family: 'Kosugi Maru', sans-serif;
`;

const Join = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 20px;
`;
const AdWrapper = styled.div`
  width: 160px !important;
`;
const QRCodeWrapper = styled.div`
  background-color: white;
  color: gray;
  padding: 20px 15px;
  margin-top: 14px;
  height: 170px;
  width: 150px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px #797979;
  border-radius: 12px;
  border-bottom: 5px solid #dcdada;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  
`;

const QRCodeDesc = styled.div`
  font-family: 'Kosugi Maru', sans-serif;
  font-size: 15px;
  padding-bottom: 10px;
`;

export default Owner