import React from 'react';
import styled from 'styled-components'

// 1. 最大値と最小値を受け取って表示
export const BackNumber = (props) => {
  const { numberList, backNumber, initialStr } = props;
  return (
    <React.Fragment>
      <Wrapper>
        <InitialStr>{initialStr}</InitialStr>
        <Numbers>
          {numberList.map(number => {
            const isHit = backNumber.includes(number);
            return <Number isHit={isHit}>{number}</Number>
          })
          }
        </Numbers>
      </Wrapper>
    </React.Fragment>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0px 0px 3px 3px;
    border-radius: 3px;
    margin: 10px 0 0px 2px;
`;
const InitialStr = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 5px 0 8px;
    font-weight: bold;
    font-size: 22px;
`;
const Numbers = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 212px;
    width: 129px;
    flex-wrap: wrap;
    font-weight: bold;
`;
const Number = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0px 3px 3px 0;
    color: #565656;
    background-color: ${props => props.isHit ? 'antiquewhite' : 'white'}
`;
