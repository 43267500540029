import React from 'react';
import { Helmet } from "react-helmet";

export const HelmetWrapper = (props) => {
  const { title, description } = props;
  const currentUrl = window.location.href;
  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content={description}/>
        <meta property="og:url" content={currentUrl}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <link rel="canonical" href={currentUrl}/>
        <title>{title}</title>
      </Helmet>
    </React.Fragment>
  )
}