import React, { useState } from 'react';
import { HelmetWrapper } from './helmet';
import styled from 'styled-components'
import { addUser } from '../../clients/firebase';

export const Join = (props) => {
  const [inputValue, setInputValue] = useState("");

  const handleOnClick = async (e) => {
    const roomId = props.match.params.room_id;
    const userId = await addUser(roomId, inputValue);
    props.history.push(`/${roomId}/user/${userId}`);
  }

  const handleChange = (event) => {
    setInputValue(event.target.value);
  }
  return (
    <React.Fragment>
      <HelmetWrapper title={`ビンゴの部屋に入室 | 無料オンラインビンゴ`} description={`このページから自身の表示名を入力してビンゴの部屋に入室することが出来ます。`} />
      <Wrapper>
        <InputWrapper>
          <DescriptionText>名前を入力してください</DescriptionText>
          <InputField placeholder={"わくわくタロウ"} value={inputValue} onChange={handleChange} />
          <CreateButton onClick={(e) => handleOnClick(e)}>部屋に入る</CreateButton>
          <AboutApp href={"https://eni-eni.com/%e7%84%a1%e6%96%99%e3%81%a7%e4%bd%bf%e3%81%88%e3%82%8b%ef%bc%81%e3%82%aa%e3%83%b3%e3%83%a9%e3%82%a4%e3%83%b3%e3%83%93%e3%83%b3%e3%82%b4/"}>このアプリについて</AboutApp>
        </InputWrapper>
        <AdWrapper>
          <a href="https://px.a8.net/svt/ejp?a8mat=3HEBHN+13W2B6+CO4+15YMPT" rel="nofollow">
            <img border="0" width="320" height="73" alt="" src="https://www29.a8.net/svt/bgt?aid=210620075067&wid=001&eno=01&mid=s00000001642007048000&mc=1" />
          </a>
          <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3HEBHN+13W2B6+CO4+15YMPT" alt="" />
        </AdWrapper>
      </Wrapper>
    </React.Fragment>
  )
}


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-flow: column;
  overflow-y: scroll;
  height: 100vh;
`;

const DescriptionText = styled.p`
  font-family: 'Kosugi Maru', sans-serif;
  letter-spacing: 4px;
  margin-bottom: 40px;
`;

const InputWrapper = styled.div`
  width: 80%;
  max-width: 500px;
  padding: 50px 15px 20px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px #797979;
  border-radius: 12px;
  border-bottom: 5px solid #dcdada;
  color: #565656;
`;

const InputField = styled.input`
  color: #565656;
  width: 80%;
  height: 46px;
  padding: 0 10px 0;
  box-sizing: border-box;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 16px;
  ::placeholder {
      color: #bbb;
  }
`;

const AdWrapper = styled.div`
  position: fixed;
  bottom: 0;
`;
const CreateButton = styled.button`
  margin: 1em;
  width: 80%;
  height: 46px;
  background: #f4b44b;
  color: white;
  border: 1px solid #f4b44b;
  border-radius: 3px;
  letter-spacing: 6px;
  font-weight: bold;
  font-family: 'Kosugi Maru', sans-serif;
  font-size: 16px;
  transition: all ease-in-out .3s;
  &:hover {
    background: #99cfaf;
    border: 1px solid #99cfaf;
    cursor: pointer;
  }
`;

const AboutApp = styled.a`
	color: #a5a5a5;
  margin-top: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  border-bottom: 1px solid #a5a5a5;
  padding: 1px;
  font-size: 15px;
`;

