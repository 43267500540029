import React from 'react';
import ReactDOM from 'react-dom';
import firebase from "firebase";

import './reset.css';
import './index.css';
import { Header } from '../src/components/rooms/header'
import reportWebVitals from './reportWebVitals';
import SetRouter from './routes/index';

var firebaseConfig = {
  apiKey: "AIzaSyBYtI2c7RNiIHzt_sadcsVCLwC_vJ0q8j8",
  authDomain: "bingo-3e125.firebaseapp.com",
  projectId: "bingo-3e125",
  storageBucket: "bingo-3e125.appspot.com",
  messagingSenderId: "846988360155",
  appId: "1:846988360155:web:f7d31b21a984d27278cffe",
  measurementId: "G-8XNN200FZV"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <Header />
    <SetRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
