import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Create } from '../components/rooms/create';
import { Join } from '../components/rooms/join';
import Owner from '../components/rooms/owner';
import User from '../components/rooms/user';
import { NotFound } from './not_found';

const SetRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Create}></Route>
        <Route path='/:room_id/owner' component={Owner}></Route>
        <Route path='/:room_id/join' component={Join}></Route>
        <Route path='/:room_id/user/:user_id' component={User}></Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default SetRouter;